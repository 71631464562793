@import url("https://fonts.googleapis.com/css?family=Satisfy&display=swap");

.Above-signup {
  margin-top: 30px;
  img {
    @media (max-width: 800px) {
      width: 450px;
    }
  }
}

.Above-signup-button {
  button {
    margin-bottom: 0px;
    background: #d6bee0;
    height: 50px;
    width: 160px;
    font-family: "Satisfy", cursive;
    font-size: 2rem;
    border: none;
    display: flex;
    text-align: right;
    margin-left: 100px;
  }
}

.Signup {
  border-top: 0.5px solid grey;
  // margin-top: 40px;
  background: #efeef3;
  // background: red;
  height: 100vh;
  img {
    margin-top: 30px;
  }
  ul {
    li {
      padding: 10px;
      // margin: 5px;
      label {
        font-size: 2.5rem;
        padding: 10px;
        // margin: 5px;
        // border: 1px solid red;
        width: 500px;
        display: flex;
        align-content: flex-start;
        @media (max-width: 800px) {
          margin: auto;
        }
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      input {
        // width: 250px;
        // height: 25px;
        width: 500px;
        height: 100px;
        margin-bottom: 20px;
        border: none;
        border-radius: 10px;
        font-size: 3rem;
        @media (max-width: 800px) {
          width: 400px;
        }
      }
    }
    button {
      // width: 100px;
      // height: 40px;
      margin-top: 20px;
      width: 200px;
      height: 80px;
      border: 2px double black;
      border-radius: 10px;
      // font-size: 1.3rem;
      font-size: 2rem;
      background: #efeef3;
    }

    h1 {
      margin-top: 40px;
      width: 500px;
      margin: auto;
      justify-content: center;
      border-bottom: 1px solid black;
      @media (max-width: 800px) {
        width: 450px;
        margin-left: 0px;
      }
    }

    h6 {
      font-size: 1rem;
      margin: auto;
    }
  }
}
