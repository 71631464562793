@import url(https://fonts.googleapis.com/css?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css?family=Satisfy&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Satisfy", cursive;
}

.Above-signup {
  margin-top: 30px; }
  @media (max-width: 800px) {
    .Above-signup img {
      width: 450px; } }

.Above-signup-button button {
  margin-bottom: 0px;
  background: #d6bee0;
  height: 50px;
  width: 160px;
  font-family: "Satisfy", cursive;
  font-size: 2rem;
  border: none;
  text-align: center; }

.Login {
  border-top: 0.5px solid grey;
  background: #efeef3;
  height: 100vh; }
  .Login img {
    margin-top: 30px; }
  .Login ul li {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .Login ul li label {
      font-size: 2.5rem;
      padding: 10px;
      width: 500px;
      display: flex;
      align-content: flex-start; }
      @media (max-width: 800px) {
        .Login ul li label {
          margin: auto; } }
    .Login ul li input {
      width: 500px;
      height: 100px;
      margin-bottom: 20px;
      border: none;
      border-radius: 10px;
      font-size: 3rem; }
      @media (max-width: 800px) {
        .Login ul li input {
          width: 400px; } }
  .Login ul button {
    margin-top: 20px;
    width: 200px;
    height: 80px;
    border: 2px double black;
    border-radius: 10px;
    font-size: 2rem;
    background: #efeef3; }
  .Login ul h1 {
    margin-top: 40px;
    width: 500px;
    margin: auto;
    justify-content: center;
    border-bottom: 1px solid black; }
    @media (max-width: 800px) {
      .Login ul h1 {
        width: 450px;
        margin-left: 0px; } }
  .Login ul h6 {
    font-size: 1rem;
    margin: auto; }

.Above-signup {
  margin-top: 30px; }
  @media (max-width: 800px) {
    .Above-signup img {
      width: 450px; } }

.Above-signup-button button {
  margin-bottom: 0px;
  background: #d6bee0;
  height: 50px;
  width: 160px;
  font-family: "Satisfy", cursive;
  font-size: 2rem;
  border: none;
  display: flex;
  text-align: right;
  margin-left: 100px; }

.Signup {
  border-top: 0.5px solid grey;
  background: #efeef3;
  height: 100vh; }
  .Signup img {
    margin-top: 30px; }
  .Signup ul li {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .Signup ul li label {
      font-size: 2.5rem;
      padding: 10px;
      width: 500px;
      display: flex;
      align-content: flex-start; }
      @media (max-width: 800px) {
        .Signup ul li label {
          margin: auto; } }
    .Signup ul li input {
      width: 500px;
      height: 100px;
      margin-bottom: 20px;
      border: none;
      border-radius: 10px;
      font-size: 3rem; }
      @media (max-width: 800px) {
        .Signup ul li input {
          width: 400px; } }
  .Signup ul button {
    margin-top: 20px;
    width: 200px;
    height: 80px;
    border: 2px double black;
    border-radius: 10px;
    font-size: 2rem;
    background: #efeef3; }
  .Signup ul h1 {
    margin-top: 40px;
    width: 500px;
    margin: auto;
    justify-content: center;
    border-bottom: 1px solid black; }
    @media (max-width: 800px) {
      .Signup ul h1 {
        width: 450px;
        margin-left: 0px; } }
  .Signup ul h6 {
    font-size: 1rem;
    margin: auto; }

.navTab {
  font-family: "Satisfy", cursive;
  font-size: 2em; }

.today {
  height: 100%;
  background-color: #d6bee0; }
  @media (max-width: 800px) {
    .today img {
      width: 400px; } }

.submit {
  background-color: #80c9db;
  border: 1px solid black; }

.todayHeader {
  font-family: "Satisfy", cursive;
  background-color: #5d147c;
  font-size: 2em; }

.ui.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto; }

.paragraph {
  background-color: #80c9db; }

.submit-class {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  width: 600px; }
  @media (max-width: 800px) {
    .submit-class {
      flex-direction: column;
      align-items: center; } }
  @media (max-width: 800px) {
    .submit-class input {
      width: 400px; } }
  .submit-class button {
    height: 2em;
    width: 50px;
    width: 50px; }
  .submit-class .ui.button {
    padding: 2px; }

.customButtonBorder {
  border: 2px solid #918f95; }

.today-quote {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7em;
  margin: auto;
  margin-bottom: 2em;
  width: 75%;
  border-bottom: 2px solid #918f95;
  border-top: 2px solid #918f95; }
  .today-quote .editBlock {
    display: flex;
    flex-direction: row;
    margin: 10px; }

.edit {
  background-color: #bfe6ed; }

.input-field {
  height: 2rem;
  width: 800px; }
  @media (max-width: 800px) {
    .input-field {
      width: 70%;
      height: 20px;
      margin-left: -120px; } }

.pushLeft {
  margin-left: 12.5%; }

.yesterday {
  background-color: #d6bee0;
  height: 100%; }
  @media (max-width: 800px) {
    .yesterday img {
      width: 400px; } }

.submit {
  background-color: #80c9db;
  border: 1px solid black; }

.todayHeader {
  font-family: "Satisfy", cursive;
  color: #d6bee0;
  font-size: 2em; }

.form {
  display: flex;
  flex-direction: column; }

.paragraph {
  width: 75%;
  margin: auto;
  padding: 10px;
  text-align: left;
  background-color: #bfe6ed;
  border: 2px solid #918f95;
  border-radius: 5px;
  margin-bottom: 20px; }

.dateDisplay {
  padding-top: 20px; }

.pushLeft {
  margin-left: 12.5%; }

.main-container {
  background-color: #d6bee0;
  height: 100%;
  width: 100%; }
  @media (max-width: 800px) {
    .main-container img {
      width: 400px;
      margin: auto; } }
  .main-container .Calendar {
    display: flex;
    flex-direction: column-reverse;
    width: 75%;
    margin: auto; }
    .main-container .Calendar .date-and-note {
      margin-bottom: 20px;
      border: 2px solid #918f95;
      border-radius: 3px;
      display: flex;
      flex-direction: column-reverse;
      background-color: #bfe6ed; }
    @media (max-width: 800px) {
      .main-container .Calendar {
        width: 500px;
        margin: auto; } }

.pushLeft {
  margin-left: 12.5%; }

